import { ListItemText, ListItem as MuiListItem } from '@mui/material'

export interface ItemProps {
  primary: string
  secondary: string
}

const ListItem = ({ primary, secondary }: ItemProps) => (
  <MuiListItem sx={{ p: 0 }}>
    <ListItemText
      primary={primary}
      secondary={secondary}
      slotProps={{
        primary: { sx: { mr: primary ? 1 : 0 } },
        secondary: { sx: { fontSize: '1.6rem', overflow: 'hidden', textOverflow: 'ellipsis' } },
      }}
      sx={{
        my: 0,
        display: 'flex',
        alignItems: 'center',
        width: '20.4rem',
        whiteSpace: 'nowrap',
      }}
    />
  </MuiListItem>
)

export default ListItem
