import { SearchFormHitText } from '@/components/atoms'
import { useConnectStates } from '@/contexts/connect'
import type { HitAudioBook } from '@/libs/Algolia'
import { INSIGHT_METHOD_NAME, searchInsightClient } from '@/libs/Algolia'
import { Link, type LinkProps } from '@mui/material'

export interface HitProps extends LinkProps {
  hit: HitAudioBook
  queryID: string
  hitPosition: number
}

const Hit = ({ hit, queryID, hitPosition }: HitProps) => {
  const { userinfo } = useConnectStates(['userinfo'])

  return (
    <Link
      href={`/audiobook/${hit.objectID}?queryId=${queryID}`}
      onClick={() =>
        searchInsightClient(INSIGHT_METHOD_NAME.clickedObjectIDs, {
          queryID: queryID,
          objectIDs: [hit.objectID],
          positions: [hitPosition],
          index: process.env.ALGOLIA_INDEX_NAME,
          eventName: 'click header form',
          userToken: userinfo?.userId,
        })
      }
    >
      <SearchFormHitText variant="body2" color="inherit">
        {hit.title}
      </SearchFormHitText>
      {hit.authors?.[0]?.name && (
        <SearchFormHitText className="author" variant="body2" color="inherit">
          {hit.authors[0].name}
        </SearchFormHitText>
      )}
    </Link>
  )
}
export default Hit
