import { Typography, type TypographyProps } from '@mui/material'

interface HeadingH3Props extends TypographyProps {}

const HeadingH3 = ({ children, sx, ...rest }: HeadingH3Props) => (
  <Typography variant="h3" {...rest} sx={{ py: 2, ...sx }}>
    {children}
  </Typography>
)

export default HeadingH3
