import { Typography, type TypographyProps } from '@mui/material'

interface HeadingH2Props extends TypographyProps {}

const HeadingH2 = ({ children, sx, ...rest }: HeadingH2Props) => (
  <Typography variant="h2" align="center" {...rest} sx={{ px: 2, py: 3, ...sx }}>
    {children}
  </Typography>
)

export default HeadingH2
